

















































































import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api/index";
import {DataDictionaryDto, SchoolContactDto, SelectListItem} from "@/api/appService";

@Component({
  name: "ContactAdd"
})
export default class ContactAdd extends Vue {
  dutyList: DataDictionaryDto[] = [];
  isLoading = false;
  isEdit = false;
  isPrimary = true;
  labelPosition = "top";
  currentSchool = ""; //所在学校
  currentContactId = 0;
  formData: SchoolContactDto = {
    contactName: "",
    duty: "",
    sex: "男",
    emailAddress: "",
    qq: "",
    mobilePhone: ""
  };
  genderList: SelectListItem[] = [];

  rules = {
    contactName: [{required: true, message: "请输入联系人名称", trigger: "blur"}],
    mobilePhone: [{required: true, message: "请输入手机号码", trigger: "blur"},
      {
        validator: this.checkPhone,
        trigger: "blur"
      }],
    emailAddress: [{required: true, message: "请输入邮箱", trigger: "blur"}, {
      type: "email",
      message: "请输入正确的邮箱地址",
      trigger: ["blur", "change"]
    }],
    // qq: [{ required: true, message: "请输入QQ", trigger: "blur" },{
    //   validator: this.checkQQ,
    //   trigger: "blur"
    // }]
  };


  async created() {
    const {schoolName} = await this.getCurrentSchoolName();
    this.currentSchool = schoolName!;
    if (this.$route.params.contactId && Number(this.$route.params.contactId) > 0) {
      this.isEdit = true;
      //此处写根据联系人id获取该联系人的信息的代码
      const contactInfo = await api.school.getContact({
        id: Number(this.$route.params.contactId)
      });
      this.formData = contactInfo;
      this.currentContactId = contactInfo.id!;
    }
    this.genderList = await this.getGenderList();
    this.dutyList = await this.getDutyList()
  }

  getCurrentSchoolName() {
    return api.school.get({id: Number(this.$route.params.schoolId)});
  }

  checkPhone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("手机号不能为空"));
    } else {
      const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    }
  }

  checkQQ(rule: any, value: any, callback: any) {
    if (value) {
      const reg = /^[1-9][0-9]{4,10}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的QQ号"));
      }
    }
  }

  onSubmit(formName: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$refs[formName]!.validate((valid: unknown) => {
      if (valid) {
        this.isLoading = true;
        this.$set(this.formData, "schoolId", Number(this.$route.params.schoolId));
        this.$set(this.formData, "id", this.currentContactId);
        if (!this.isEdit) {
          api.school.addContact({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();

          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        } else {
          api.school.updateContact({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();

          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        }
      } else {
        this.$message.error("提交失败");
        this.isLoading = false;
        return false;
      }
    });
  }

  getDutyList() {
    return api.dataDictionary.getDataDictionaryByKey({key: "Duty"})
  }

  getGenderList() {
    return api.enumService.getValues({typeName: "Sex"});
  }

  back() {
    this.$router.go(-1);
  }
}
